import { isMobile } from '../../components/Hooks/CustomHooks';

import sodastreamImage1 from '../../assets/sodastream-image1.jpg';
import sodastreamImage2 from '../../assets/sodastream-image2.jpg';
import sodastreamImage3 from '../../assets/sodastream-image3.jpg';

import { SodastreamLogo } from '../../assets/sodastream-logo';

import './sodastream.styles.css';
import '../../styles/styles.css';

const baseUrl =
  'https://firebasestorage.googleapis.com/v0/b/talia-portfolio.appspot.com/o/Talia%2FPortfolio%2FAssets%2F';
const sodaStreamBannerMB = `${baseUrl}sodastream-banner-mb.jpg?alt=media&token=275467ad-8a64-4f1d-aabc-b110c29176e4`;
const sodaStreamBannerDT = `${baseUrl}sodastream-banner-dt.jpg?alt=media&token=ac4f6e0a-da5a-446a-a5ae-8a3a542f62ff`;
// const sodastreamImage1 = `${baseUrl}sodastream-image1.png?alt=media&token=57f2ebfb-bfba-4717-afc0-411c2d86cd8e`;
// const sodastreamImage2 = `${baseUrl}sodastream-image2.png?alt=media&token=8a0df4a2-2142-42fb-9210-bd46755b278f`;
// const sodastreamImage3 = `${baseUrl}sodastream-image3.png?alt=media&token=4fed9b8d-fce6-4702-892c-1db905a2df3b`;

export const SodastreamPage = () => (
  <div className='container-portfolio'>
    <div>
      <img
        src={isMobile() ? sodaStreamBannerMB : sodaStreamBannerDT}
        className='banner-image'
      />
    </div>
    <div className='wrapper-portfolio'>
      <div className='column-portfolio'>
        {isMobile() ? <SodastreamLogo width='140' /> : <SodastreamLogo />}
      </div>
      <div className='column-portfolio2'>
        <div className='text-section'>
          <div className='title-3'>digital marketing design</div>
          <div className='title-4'>2019- 2021</div>
        </div>
        <div className='row-portfolio'>
          <div className='text-section'>
            <div className='text-2'>about.</div>
            <div className='text-3'>
              SodaStream strives to transform the way the world drinks sparkling
              water while protecting the environment by reducing single-use
              plastic.
            </div>
          </div>
          <div className='text-section'>
            <div className='text-2'>projects.</div>
            <div className='text-3'>
              email marketing, landing pages & PPC campaigns
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='wrapper-portfolio-images'>
      <img src={sodastreamImage1} className='image' />
      <div className='sodastream-column'>
        <img src={sodastreamImage2} className='image' />
        <img src={sodastreamImage3} className='image' />
      </div>
    </div>
  </div>
);
