import { ReactComponent as Arrow } from '../../assets/arrow.svg';

import '../../index.css';
import './about.styles.css';
import '../../styles/styles.css';

const baseUrl =
  'https://firebasestorage.googleapis.com/v0/b/talia-portfolio.appspot.com/o/Talia%2FPortfolio%2FAssets%2F';
const aboutImage1 = `${baseUrl}about-image1.jpg?alt=media&token=98cd6735-bc68-484e-8358-73e4d26fbb14`;

export const AboutPage = () => (
  <div className='container-about'>
    <div className='column-about'>
      <div className='text-1'>about.</div>
      <div className='row-1'>
        <div className='title-1'>talia sirelle barkai</div>
        <div className='title-2'>UX/UI Designer</div>
      </div>
      <div className='text-3 row-2'>
        Born in NY. Based in TLV.
        <p>
          Hi! My name is Talia. I’m an optimistic, creative, and detail-oriented
          UX/UI designer passionate about crafting engaging digital experiences.
          With 6+ years of hands-on experience in design spanning across digital
          marketing, graphic design, and UX/UI, I have delivered end-to-end
          designs for numerous e-commerce brands including Tenengroup, AHAVA and
          SodaStream.
        </p>
        <p>
          At Tenengroup, I introduced entirely new, modern design systems and
          had the privilege of redesigning the UI for two global jewelry brands
          that specialize in name plates and jewelry personalization —
          <a href='https://www.oakandluna.com' target='_blank'>
            {' '}
            Oak and Luna
          </a>{' '}
          &{' '}
          <a href='https://www.myka.com' target='_blank'>
            MYKA
          </a>{' '}
          elevating the user engagement and drastically enhancing the overall
          website’s look and feel.
        </p>
        <p>
          At{' '}
          <a href='https://www.ahava.com' target='_blank'>
            AHAVA
          </a>
          , a company renowned for transforming the natural wonders of the Dead
          Sea into modern skincare, I led the digital design efforts including
          the global digital guidelines that were applied to the new Shopify
          website and marketing collateral to ensure design consistency.
        </p>
        <p>
          At{' '}
          <a href='https://www.sodastream.com' target='_blank'>
            SodaStream
          </a>
          , I was the first digital marketing designer in the global e-commerce
          department, and collaborated closely with the digital marketing team
          crafting all visual aspects from email marketing, digital ads to
          landing pages.
        </p>
        <p>
          Growing up, I was always tech-savvy with a deep dedication in web
          design, HTML/CSS, and everything in between. My major in Graphic
          Design at Queens College was the beginning of my design journey which
          led me to where I am today — to deliver effective and exceptional
          designs that are aligned with business goals.
        </p>
      </div>
      <div className='row-3'>
        <div className='text-1'>get in touch.</div>
        <div
          className='text-3 row-4'
          onClick={() => window.open('mailto:tsbarkai@gmail.com')}
        >
          <div>tsbarkai@gmail.com</div>
          <Arrow />
        </div>
      </div>
    </div>
    <div className='column-img'>
      <img src={aboutImage1} alt='Avatar' className='image' />
    </div>
  </div>
);
