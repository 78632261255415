import './dropdown-menu.styles.css';

export const DropdownMenu = ({ items }) => {
  const listItems = items.map((item) => <li>{item}</li>);

  return (
    <div className='dropdown-menu'>
      <ul>{listItems}</ul>
    </div>
  );
};
