import Resume from '../../assets/Talia Barkai - Resume.pdf';

import { ReactComponent as Arrow } from '../../assets/arrow.svg';

import '../../index.css';
import './resume.styles.css';
import '../../styles/styles.css';

export const ResumePage = () => {
  const downloadResume = () => {
    const pdfUrl = Resume;
    const link = document.createElement('a');

    link.href = pdfUrl;
    link.download = 'Talia Barkai - Resume.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className='main-container'>
      <div className='container-resume'>
        <div className='column-resume'>
          <div className='text-1'>resume.</div>
          <div className='title-2'>talia sirelle barkai</div>
        </div>
        <div className='column-resume-link'>
          <div className='text-3 row-4' onClick={() => downloadResume()}>
            download full resume
            <Arrow />
          </div>
        </div>
      </div>
      <div className='container-resume-title text-1'>experience.</div>
      <div className='container-resume-inner'>
        <div className='column-resume'>
          <div className='job-description'>
            <div className='job-title'>Lead UX/UI Designer</div>
            <div className='title-4 company'>
              Tenengroup - House of Brands | Personalization Jewelry
            </div>
            <div className='date'>February 2022 to Present </div>
          </div>
        </div>
        <div className='bullets text-3'>
          <p>
            - Developed responsive design and typography systems for global
            brand websites, managing design team’s work and quality to ensure
            consistency.
          </p>
          <p>
            - Redesigned and updated global design toolkits for two global
            brands, MYKA, and Oak & Luna, updating their digital image and
            increasing brand recognition, recall, and sales.
          </p>
          <p>
            - Created and successfully implemented new UX/UI systems for “add to
            cart” pages across four global brands and designed interactive
            prototypes using each brand’s visual language and main components to
            develop new features across all global sites.
          </p>
          <p>
            - Collaborated with product management and developer team to
            maintain design consistency and coherence to style guides across all
            digital features.
          </p>
        </div>
      </div>
      <div className='container-resume'>
        <div className='column-resume'>
          <div className='job-description'>
            <div className='job-title'>
              Lead Product Designer, <p>Global E-Commerce</p>
            </div>
            <div className='title-4 company'>
              AHAVA Dead Sea Laboratories | Skincare
            </div>
            <div className='date'>January 2021 to February 2022</div>
          </div>
        </div>
        <div className='bullets text-3'>
          <p>
            - Developed & redesigned the global AHAVA digital identity,
            creating, maintaining, and overseeing all assets to promote brand
            consistency across all digital platforms, websites, and product
            launches.
          </p>
          <p>
            - Oversaw all design for AHAVA’s Shopify customizations and
            integrations by researching testing, and creating prototypes for new
            brand website features and platform updates.
          </p>
          <p>
            - Collaborated with in-house studio, preparing all production items
            for photoshoots including shot lists and prop itemizations.
          </p>
        </div>
      </div>
      <div className='container-resume'>
        <div className='column-resume'>
          <div className='job-description'>
            <div className='job-title'>
              Digital Marketing Designer, <p>Global E-Commerce</p>
            </div>
            <div className='title-4 company'>
              SodaStream International | #1 Sparkling Water Brand
            </div>
            <div className='date'>April 2019 to January 2021</div>
          </div>
        </div>
        <div className='bullets text-3'>
          <p>
            - Created campaigns and visual designs for the acquisition team,
            then conducted A/B testing to verify the SodaStream brands success
            in marketing across all channels.
          </p>
          <p>
            - Conceptualized, produced, and successfully delivered all email
            marketing designs within routinely short turnaround times.
          </p>
          <p>
            - Managed all freelancers and external agencies/vendors to ensure
            SodaStream’s brand identity remained consistent across the web and
            social.
          </p>
        </div>
      </div>
      <div className='container-resume'>
        <div className='column-resume'>
          <div className='job-description'>
            <div className='job-title'>
              Design Lead / Brick & Mortar Manager
            </div>
            <div className='title-4 company'>
              Vintage Grapes | Premium Wine & Spirits
            </div>
            <div className='date'>November 2013 to December 2018</div>
          </div>
        </div>
        <div className='bullets text-3'>
          <p>
            - Created a design kit that was implemented in-store and online,
            creating a visual identity for Vintage Grapes for the first time.
          </p>
          <p>
            - Curated a strong online presence (from scratch) on social media
            and through successful email marketing that ultimately drove a 25%
            increase in sales.
          </p>
          <p>
            - Managed and trained all associates to ensure outstanding customer
            service both in-person and online.
          </p>
        </div>
      </div>
      <div className='container-resume-title text-1'>education.</div>

      <div className='container-resume-inner'>
        <div className='column-resume'>
          <div className='job-description'>
            <div className='job-title'>Bachelor of Science, Graphic Design</div>
            <div className='title-4 company'>Queens College, New York</div>
            <div className='date'>2015</div>
          </div>
        </div>
        <div className='column-resume'>
          <div className='job-description'>
            <div className='job-title'>
              Study Abroad, Middle Eastern Studies
            </div>
            <div className='title-4 company'>Tel Aviv University, Israel</div>
            <div className='date'>2011</div>
          </div>
        </div>
      </div>
      <div className='bottom-link'>
        <div className='text-3 row-4' onClick={() => downloadResume()}>
          download full resume
          <Arrow />
        </div>
      </div>
    </div>
  );
};
