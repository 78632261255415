import React, { useEffect, Fragment } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import { Header } from './components/Header';
import { Footer } from './components/Footer';
import {
  HomePage,
  MykaPage,
  AboutPage,
  AhavaPage,
  ResumePage,
  SodastreamPage,
  OakAndLunaPage,
} from './pages';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const router = (
  <Fragment>
    <Header />
    <ScrollToTop />
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/myka' element={<MykaPage />} />
      <Route path='/ahava' element={<AhavaPage />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/homepage' element={<HomePage />} />
      <Route path='/resume' element={<ResumePage />} />
      <Route path='/sodastream' element={<SodastreamPage />} />
      <Route path='/oak-and-luna' element={<OakAndLunaPage />} />
      <Route path='/*' element={<HomePage />} />
    </Routes>
    <Footer />
  </Fragment>
);

export const App = () => <BrowserRouter>{router}</BrowserRouter>;

export default App;
