import { useState, useLayoutEffect, useCallback } from 'react';

const getCurrentDimension = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export const useWindowWidthAndHeight = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension);

  const updateDimension = useCallback(() => {
    setScreenSize(getCurrentDimension());
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      updateDimension();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateDimension]);

  return screenSize;
};

export const isMobile = () => {
  const { width } = useWindowWidthAndHeight();

  if (width < 800) return true;
};
