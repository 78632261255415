import { isMobile } from '../../components/Hooks/CustomHooks';

import { ReactComponent as OalLogo } from '../../assets/oal-logo.svg';
import { ReactComponent as VisitWebsite } from '../../assets/visit-website.svg';

import '../../styles/styles.css';
import './oak-and-luna.styles.css';

const baseUrl =
  'https://firebasestorage.googleapis.com/v0/b/talia-portfolio.appspot.com/o/Talia%2FPortfolio%2FAssets%2F';
const mainBannerMB = `${baseUrl}oal-banner-mb.jpg?alt=media&token=007c348a-4897-4456-851d-b6aa93338ac2`;
const mainBannerDT = `${baseUrl}oal-banner-dt.jpg?alt=media&token=c60b4318-d32a-443e-bb06-073f70257404`;
const oalImage1 = `${baseUrl}oal-image1.jpg?alt=media&token=a6fa2b89-6322-4d66-b044-c43b251be3b6`;
const oalImage2 = `${baseUrl}oal-image2.jpg?alt=media&token=0f0d71e9-4873-4e21-ae5f-a3dbdd71e880`;
const oalImage3 = `${baseUrl}oal-image3.jpg?alt=media&token=261ed6d8-f9f1-48fb-8945-489072070782`;
const oalImage4 = `${baseUrl}oal-image4.jpg?alt=media&token=5c5bb275-32c0-4541-a17f-aa7570ae7bf6`;

export const OakAndLunaPage = () => (
  <div className='container-portfolio'>
    <div>
      <img
        src={isMobile() ? mainBannerMB : mainBannerDT}
        className='banner-image'
      />
    </div>
    <div className='wrapper-portfolio'>
      <div className='column-portfolio'>
        {isMobile() ? <OalLogo width='140' /> : <OalLogo />}
      </div>
      <div className='column-portfolio2'>
        <div className='section-with-link'>
          <div className='text-section'>
            <div className='title-3'>UX/UI Designer</div>
            <div className='title-4'>2022 - present</div>
          </div>
          <div className='visit-website'>
            <VisitWebsite
              onClick={() => window.open('https://www.oakandluna.com/')}
            />
          </div>
        </div>
        <div className='row-portfolio'>
          <div className='text-section'>
            <div className='text-2'>about.</div>
            <div className='text-3'>
              Oak & Luna is a custom designer jewelry brand that aspires to
              empower women with personalized, high-quality jewelry inspired by
              beauty, power and strength.
            </div>
          </div>
          <div className='text-section'>
            <div className='text-2'>projects.</div>
            <div className='text-3'>
              website UI redesign, UX/UI, design system implementation, landing
              pages, key club branding and email templates.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='wrapper-portfolio-images-oal'>
      <img src={oalImage1} className='image' />
      <div className='wrapper-portfolio-image-inner'>
        <img src={oalImage2} className='image' />
        <img src={oalImage3} className='image' />
      </div>
      <img src={oalImage4} className='image' />
    </div>
  </div>
);
