import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SidebarLink = styled(Link)`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  list-style: none;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  font-family: 'Outfit', sans-serif;

  &:link { 
    text-decoration: none; 
  }
  &:hover {
    cursor: pointer;
    text-decoration: none; 
    border-bottom: 1px solid #00000;
  }
  &:visited {
    color: #00000;
    cursor: pointer;
    text-decoration: none; 
    flex-direction: column;
  }
  &:active {
    text-decoration: none;
  }
`;

export const SidebarLabel = styled.span`
  margin-left: 0px;
`;

export const DropdownLink = styled(Link)`
  height: 20px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  text-decoration: none;
  font-family: 'Outfit', sans-serif;

  &:link { 
    text-decoration: none; 
  } 
  &:visited { 
    text-decoration: none; 
  } 
  &:hover { 
    cursor: pointer;
    text-decoration: none; 
  } 
  &:active { 
    text-decoration: none; 
  }
`;

export const StyledLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const StyledSubLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledIcon = styled.div`
  float: right;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.3s ease-out;
  transform: ${(props) => (props?.rotate ? 'rotate(180deg)' : '')};
`;

export const StyledSubMenuDropdown = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;

export const StyleSubMenuSubItem = styled.div`
  display: grid;
  padding-top: 20px;
  gap: 12px;
`;
