import { isMobile } from '../../components/Hooks/CustomHooks';

import { ReactComponent as MykaLogo } from '../../assets/myka-logo.svg';
import { ReactComponent as MykaImage1 } from '../../assets/myka-image1.svg';
import { ReactComponent as MykaImage2 } from '../../assets/myka-image2.svg';
import { ReactComponent as MykaImage3 } from '../../assets/myka-image3.svg';
import { ReactComponent as VisitWebsite } from '../../assets/visit-website.svg';

import './myka.styles.css';
import '../../styles/styles.css';

const baseUrl =
  'https://firebasestorage.googleapis.com/v0/b/talia-portfolio.appspot.com/o/Talia%2FPortfolio%2FAssets%2F';
const mykaBannerMB = `${baseUrl}myka-banner-mb.jpg?alt=media&token=3bf85f44-ae46-49c4-bd1e-91cf1bc509fc`;
const mykaBannerDT = `${baseUrl}myka-banner-dt.jpg?alt=media&token=aa19b5ae-cc79-4abf-9961-01fa06eec6d7`;

export const MykaPage = () => (
  <div className='container-portfolio'>
    <div>
      <img
        src={isMobile() ? mykaBannerMB : mykaBannerDT}
        className='banner-image'
      />
      ;
    </div>
    <div className='wrapper-portfolio'>
      <div className='column-portfolio'>
        {isMobile() ? <MykaLogo width='140' /> : <MykaLogo />}
      </div>
      <div className='column-portfolio2'>
        <div className='section-with-link'>
          <div className='text-section'>
            <div className='title-3'>UX/UI Designer</div>
            <div className='title-4'>2022 - present</div>
          </div>
          <div className='visit-website'>
            <VisitWebsite
              onClick={() => window.open('https://www.myka.com/')}
            />
          </div>
        </div>
        <div className='row-portfolio'>
          <div className='text-section'>
            <div className='text-2'>about.</div>
            <div className='text-3'>
              MYKA, formerly known as My Name Necklace and founded in 2006,
              specalizes in modern nameplates and personalized jewelry.
            </div>
          </div>
          <div className='text-section'>
            <div className='text-2'>projects.</div>
            <div className='text-3'>
              website UI redesign, UX/UI, design system implementation, landing
              pages, loyalty branding to increase customer engagement on site.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='wrapper-portfolio-images-myka'>
      <MykaImage1 className='image' />
      <MykaImage2 className='image' />
      <MykaImage3 className='image' />
    </div>
  </div>
);
