import React, { useState, Fragment } from 'react';
import { IconContext } from 'react-icons/lib';

import { SubMenu } from './SubMenu';
import { MainIcon } from '../../assets/MainIcon';
import { ReactComponent as DropdownArrow } from '../../assets/dropdown-arrow.svg';
import {
  Nav,
  NavIcon,
  SidebarNav,
  SidebarWrap,
  SidebarLinks,
} from './sidebar.styles';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { ReactComponent as HamburgerMenuIcon } from '../../assets/hamburger-menu.svg';

export const SidebarData = [
  {
    title: 'work.',
    icon: <DropdownArrow />,
    subNav: [
      {
        title: 'sodastream',
        path: '/sodastream',
      },
      {
        title: 'ahava',
        path: '/ahava',
      },
      {
        title: 'oak and luna',
        path: '/oak-and-luna',
      },
      {
        title: 'myka',
        path: '/myka',
      },
    ],
  },
  {
    title: 'about.',
    path: '/about',
  },
  {
    title: 'resume.',
    path: '/resume',
  },
];

export const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  return (
    <Fragment>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav>
          <NavIcon to='#'>
            <HamburgerMenuIcon onClick={showSidebar} />
          </NavIcon>
        </Nav>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to='#'>
              <MainIcon />
              <CloseIcon onClick={showSidebar} />
            </NavIcon>
            <SidebarLinks>
              {SidebarData.map((item, index) => (
                <SubMenu item={item} key={index} onClick={() => setSidebar(false)} />
              ))}
            </SidebarLinks>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </Fragment>
  );
};
