import { isMobile } from '../../components/Hooks/CustomHooks';
import { ReactComponent as AhavaLogo } from '../../assets/ahava-logo.svg';

import './ahava.styles.css';

const baseUrl =
  'https://firebasestorage.googleapis.com/v0/b/talia-portfolio.appspot.com/o/Talia%2FPortfolio%2FAssets%2F';
const ahavaBannerMB = `${baseUrl}ahava-banner-mb.jpg?alt=media&token=ac9dc726-56bf-47b8-8f73-d28b50cb76c0`;
const ahavaBannerDT = `${baseUrl}ahava-banner-dt.jpg?alt=media&token=90604c01-b970-4c34-b10a-0966d7b02a06`;
const ahavaImage1 = `${baseUrl}ahava-image1.jpg?alt=media&token=d8b52881-0976-4fa8-9a5b-23d2c2810098`;
const ahavaImage2 = `${baseUrl}ahava-image2.jpg?alt=media&token=6c53122d-4d6b-4138-8259-1743e8efa20f`;
const ahavaImage3 = `${baseUrl}ahava-image3.jpg?alt=media&token=d57b0f60-d84c-4f70-b0fb-32251f30d873`;
const ahavaImage4 = `${baseUrl}ahava-image4.jpg?alt=media&token=6c6ce83c-e0eb-4d93-9484-38d37f66b172`;

export const AhavaPage = () => (
  <div className='container-portfolio'>
    <div>
      <img
        src={isMobile() ? ahavaBannerMB : ahavaBannerDT}
        className='banner-image'
      />
    </div>
    <div className='wrapper-portfolio'>
      <div className='column-portfolio'>
        {isMobile() ? <AhavaLogo width='140' /> : <AhavaLogo />}
      </div>
      <div className='column-portfolio2'>
        <div className='text-section'>
          <div className='title-3'>product design</div>
          <div className='title-4'>2021 - 2022</div>
        </div>
        <div className='row-portfolio'>
          <div className='text-section'>
            <div className='text-2'>about.</div>
            <div className='text-3'>
              AHAVA is a cosmetic company renowned for transforming the natural
              wonders of the Dead Sea into modern skincare.
            </div>
          </div>
          <div className='text-section'>
            <div className='text-2'>projects.</div>
            <div className='text-3'>
              digital brand guidelines, photoshoot compositions, product designs
              for new integrations & features on site.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='wrapper-portfolio-images-ahava'>
      <div className='wrapper-portfolio-image-inner-ahava'>
        <img src={ahavaImage1} className='image' />
        <img src={ahavaImage2} className='image' />
      </div>
      <div className='wrapper-portfolio-image-inner2-ahava'>
        <img src={ahavaImage3} className='image' />
        <img src={ahavaImage4} className='image' />
      </div>
    </div>
  </div>
);
