import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Sidebar } from '../SideBar';
import { DropdownMenu } from '../DropdownMenu';
import { useWindowWidthAndHeight } from '../Hooks/CustomHooks';

import './header.styles.css';
import { MainIcon } from '../../assets/MainIcon';

export const Header = () => {
  const { width } = useWindowWidthAndHeight();
  const dropdownItems = [
    <Link className='hover-links' to={'/sodastream'}>
      sodastream
    </Link>,
    <Link className='hover-links' to={'/ahava'}>
      ahava
    </Link>,
    <Link className='hover-links' to={'/oak-and-luna'}>
      oak and luna
    </Link>,
    <Link className='hover-links' to={'/myka'}>
      myka
    </Link>,
  ];
  const [iconColor, setIconColor] = useState('black');
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleIconMouseEnter = () => {
    setIconColor('black');
  };

  const handleIconMouseLeave = () => {
    setIconColor('#384742');
  };

  const handleDropdownMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleDropdownMouseLeave = () => {
    setDropdownVisible(false);
  };

  const renderMainLogo = (
    <Link
      className='logo-hover header-icon'
      to='/homepage'
      onMouseLeave={handleIconMouseEnter}
      onMouseEnter={handleIconMouseLeave}
    >
      <MainIcon color={iconColor} />
    </Link>
  );

  const renderDesktopMenu = (
    <div className='header-tabs'>
      <div
        className='header-menu tab-text'
        onMouseEnter={handleDropdownMouseEnter}
        onMouseLeave={handleDropdownMouseLeave}
      >
        work.
        {isDropdownVisible && <DropdownMenu items={dropdownItems} />}
      </div>

      <Link className='link' to={'/about'}>
        <div className='tab-text' title='about.'>
          about.
        </div>
      </Link>
      <Link className='link' to={'/resume'}>
        <div className='tab-text' title='resume.'>
          resume.
        </div>
      </Link>
    </div>
  );

  const renderMobileMenu = <Sidebar />;

  return (
    <div className='header'>
      {renderMainLogo}

      {width > 800 ? (
        <Fragment>{renderDesktopMenu}</Fragment>
      ) : (
        <Fragment>{renderMobileMenu}</Fragment>
      )}
    </div>
  );
};
