import { ReactComponent as LinkedInIcon } from '../../assets/linkedin.svg';

import './footer.styles.css';

export const Footer = () => (
  <div className={'footer'}>
    <div className='title-3'>get in touch.</div>
    <div
      className='div-wrapper title-4'
      onClick={() => window.open('mailto:tsbarkai@gmail.com')}
    >
      tsbarkai@gmail.com
    </div>
    <div className='pages'>
      <LinkedInIcon
        className='text-wrapper'
        onClick={() => window.open('https://www.linkedin.com/in/talia-barkai/')}
      />
    </div>
    <div className='div-wrapper'>
      <p className='p'>© 2024 | designed by talia sirelle barkai</p>
    </div>
  </div>
);
