import React from 'react';
import PropTypes from 'prop-types';

export const MainIcon = ({ color = 'black', className }) => (
  <svg
    className={`tsb-6 ${className}`}
    fill='none'
    height='39'
    viewBox='0 0 50 39'
    width='50'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      className='path'
      d='M5.64157 31.2632V2.00781H10.3741V28.6297V31.2632H5.64157ZM0.69397 14.9146V10.6123H15.3217V14.9146H0.69397Z'
      fill={color}
    />
    <path
      className='path'
      d='M19.3991 36.983C18.2519 36.983 17.1476 36.8396 16.0864 36.5527C15.0252 36.2372 14.05 35.807 13.1609 35.2621C12.2717 34.6884 11.4973 34.0001 10.8376 33.197L13.8492 30.1854C14.5663 31.0172 15.3837 31.6482 16.3015 32.0784C17.248 32.4799 18.3092 32.6807 19.4852 32.6807C20.5464 32.6807 21.3495 32.523 21.8945 32.2075C22.4394 31.892 22.7119 31.4331 22.7119 30.8307C22.7119 30.1997 22.4538 29.7122 21.9375 29.368C21.4212 29.0238 20.7472 28.737 19.9154 28.5075C19.1123 28.2494 18.2519 27.9913 17.3341 27.7331C16.4449 27.475 15.5845 27.1308 14.7527 26.7006C13.9496 26.2417 13.2899 25.625 12.7737 24.8506C12.2574 24.0762 11.9993 23.0723 11.9993 21.839C11.9993 20.5197 12.3004 19.3867 12.9027 18.4402C13.5337 17.4937 14.4085 16.7624 15.5271 16.2461C16.6744 15.7298 18.0368 15.4717 19.6143 15.4717C21.2778 15.4717 22.7406 15.7728 24.0026 16.3752C25.2932 16.9488 26.3688 17.8236 27.2293 18.9995L24.2177 22.0111C23.6154 21.2654 22.927 20.7061 22.1526 20.3332C21.3782 19.9604 20.4891 19.7739 19.4852 19.7739C18.5387 19.7739 17.8073 19.9173 17.291 20.2042C16.7748 20.491 16.5166 20.9069 16.5166 21.4518C16.5166 22.0255 16.7748 22.47 17.291 22.7855C17.8073 23.101 18.467 23.3735 19.2701 23.6029C20.1018 23.8324 20.9623 24.0905 21.8514 24.3774C22.7693 24.6355 23.6297 25.0084 24.4328 25.4959C25.2646 25.9549 25.9386 26.5858 26.4549 27.3889C26.9711 28.1633 27.2293 29.1815 27.2293 30.4435C27.2293 32.4513 26.5266 34.0431 25.1212 35.219C23.7157 36.395 21.8084 36.983 19.3991 36.983Z'
      fill={color}
    />
    <path
      className='path'
      d='M39.7155 33.4143C38.138 33.4143 36.7326 33.0845 35.4993 32.4248C34.266 31.7364 33.2908 30.8043 32.5737 29.6283C31.8854 28.4524 31.5412 27.1187 31.5412 25.6272V19.6901C31.5412 18.1986 31.8997 16.8649 32.6168 15.689C33.3338 14.513 34.309 13.5952 35.5423 12.9355C36.7756 12.2472 38.1667 11.903 39.7155 11.903C41.5798 11.903 43.2577 12.3906 44.7491 13.3658C46.2693 14.3123 47.4596 15.603 48.32 17.2378C49.2092 18.844 49.6537 20.6653 49.6537 22.7017C49.6537 24.7094 49.2092 26.5307 48.32 28.1655C47.4596 29.7717 46.2693 31.0481 44.7491 31.9946C43.2577 32.9411 41.5798 33.4143 39.7155 33.4143ZM38.9411 28.94C40.0884 28.94 41.0922 28.6818 41.9527 28.1655C42.8418 27.6206 43.5302 26.8749 44.0178 25.9284C44.534 24.9819 44.7922 23.892 44.7922 22.6587C44.7922 21.4253 44.534 20.3354 44.0178 19.3889C43.5302 18.4424 42.8418 17.7111 41.9527 17.1948C41.0922 16.6498 40.0884 16.3774 38.9411 16.3774C37.7938 16.3774 36.7756 16.6498 35.8865 17.1948C34.9974 17.7111 34.309 18.4424 33.8214 19.3889C33.3338 20.3354 33.09 21.4253 33.09 22.6587C33.09 23.892 33.3338 24.9819 33.8214 25.9284C34.309 26.8749 34.9974 27.6206 35.8865 28.1655C36.7756 28.6818 37.7938 28.94 38.9411 28.94ZM28.6587 32.9841V2.00781H33.3912V17.4099L32.5737 22.4005L33.3912 27.4342V32.9841H28.6587Z'
      fill={color}
    />
  </svg>
);

MainIcon.propTypes = {
  color: PropTypes.string,
};
