import { useState, useEffect, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';

import Spinner from '../../components/Spinner/Spinner';
import { useWindowWidthAndHeight } from '../../components/Hooks/CustomHooks';

import mykaV2 from '../../assets/myka-variant2.jpg';
import ahavaV2 from '../../assets/ahava-variant2.jpg';
import sodastreamV2 from '../../assets/sodastream-variant2.jpg';
import oakAndLunaV2 from '../../assets/oak-and-luna-variant2.jpg';

import './homepage.styles.css';
import '../../styles/styles.css';

const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/talia-portfolio.appspot.com/o/Talia%2FPortfolio%2FAssets%2F';
const myka = `${baseUrl}myka-default.jpg?alt=media&token=3e656ecd-4150-4dc1-a757-0735aa49aed6`;
const ahava = `${baseUrl}ahava-default.jpg?alt=media&token=7de501c9-ca0f-44ae-baee-43556124c165`;
const sodastream = `${baseUrl}sodastream-default.jpg?alt=media&token=4943db34-19ca-403b-b815-4cf1c5a34215`;
const oakAndLuna = `${baseUrl}oak-and-luna-default.jpg?alt=media&token=b081b32e-abe2-417d-934d-45d3e08964de`;

const ImagePreloader = ({ src }) => {
  useEffect(() => {
    const img = new Image();
    
    img.src = src;
  }, [src]);

  return null;
};

const ImageToggleOnMouseOver = ({
  page,
  displayName = '',
  primaryImg,
  secondaryImg,
  mobileMode,
}) => {
  const imageRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
  };

  return (
    <div className='image-description'>
      <Link to={`/${page}`}>
        {loading && <Spinner />}
        <img
          className={`image-default ${loading ? 'hidden' : ''}`}
          onMouseOver={() => {
            if (secondaryImg) imageRef.current.src = secondaryImg;
          }}
          onMouseOut={() => {
            if (primaryImg) imageRef.current.src = primaryImg;
          }}
          src={primaryImg}
          alt=''
          ref={imageRef}
          onLoad={handleImageLoad}
          onError={handleImageError}
          loading='lazy'
        />
      </Link>
      {mobileMode && (
        <div className='text-2 homepage-description'>{displayName}</div>
      )}
      {secondaryImg && <ImagePreloader src={secondaryImg} />}
    </div>
  );
};

export const HomePage = () => {
  const { width } = useWindowWidthAndHeight();

  const renderDesktop = () => (
    <Fragment>
      <ImageToggleOnMouseOver
        page='sodastream'
        primaryImg={sodastream}
        secondaryImg={sodastreamV2}
        alt=''
      />
      <ImageToggleOnMouseOver
        page='ahava'
        primaryImg={ahava}
        secondaryImg={ahavaV2}
        alt=''
      />
      <ImageToggleOnMouseOver
        page='myka'
        primaryImg={myka}
        secondaryImg={mykaV2}
        alt=''
      />
      <ImageToggleOnMouseOver
        page='oak-and-luna'
        primaryImg={oakAndLuna}
        secondaryImg={oakAndLunaV2}
        alt=''
      />
    </Fragment>
  );

  const renderMobile = () => (
    <Fragment>
      <ImageToggleOnMouseOver
        page='sodastream'
        displayName='sodastream'
        mobileMode
        primaryImg={sodastream}
        alt=''
      />
      <ImageToggleOnMouseOver
        page='ahava'
        displayName='ahava'
        mobileMode
        primaryImg={ahava}
        alt=''
      />
      <ImageToggleOnMouseOver
        page='myka'
        displayName='myka'
        mobileMode
        primaryImg={myka}
        alt=''
      />
      <ImageToggleOnMouseOver
        page='oak-and-luna'
        displayName='oak & luna'
        mobileMode
        primaryImg={oakAndLuna}
        alt=''
      />
    </Fragment>
  );

  return (
    <section className='container-homepage'>
      <div className='wrapper-homepage'>
        {width >= 800 ? (
          <Fragment>{renderDesktop()}</Fragment>
        ) : (
          <Fragment>{renderMobile()}</Fragment>
        )}
      </div>
    </section>
  );
};
