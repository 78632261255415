import { useState, Fragment } from 'react';

import {
  StyledIcon,
  StyledLink,
  SidebarLink,
  SidebarLabel,
  DropdownLink,
  StyledSubLinks,
  StyleSubMenuSubItem,
  StyledSubMenuDropdown,
} from './sub-menu.styles';

export const SubMenu = ({ item, onClick }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <Fragment>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <StyledLink>
          <StyledSubMenuDropdown onClick={!item.subNav ? onClick : null}>
            <SidebarLabel>{item.title}</SidebarLabel>
            <StyledIcon rotate={subnav}>{item?.icon}</StyledIcon>
          </StyledSubMenuDropdown>
          <StyledSubLinks>
            {subnav && (
              <StyleSubMenuSubItem>
                {item.subNav.map((item, index) => (
                  <DropdownLink to={item.path} key={index} onClick={onClick}>
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                  </DropdownLink>
                ))}
              </StyleSubMenuSubItem>
            )}
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
              ? item.iconClosed
              : null}
          </StyledSubLinks>
        </StyledLink>
      </SidebarLink>
    </Fragment>
  );
};
